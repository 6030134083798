'use client'

import { cn } from '@chaseweb/utils/cn'
import { usePathname } from 'next/navigation'

import { BbaLogo } from '@/components'

export const HeroBbaLogo = () => {
  const pathname = usePathname()

  return ['/product/chase-account/', '/product/chase-saver-account/'].includes(
    pathname,
  ) ? (
    <div>
      <BbaLogo
        path={pathname}
        className={cn('hero__bba-image-wrapper tw-h-14 tw-w-fit lg:tw-h-16')}
      />
    </div>
  ) : null
}
