'use client'

import NoResultsImg from '@chaseweb/assets/resources/search-bar.svg'
import { Button, Heading } from '@chaseweb/ui-library/src'
import { useRouter, useSearchParams } from 'next/navigation'
import type { HTMLAttributes } from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'

import { Link, NextImage } from '@/components'
import type { SupportArticle } from '@/data/aem'

import {
  configureSearchEngine,
  getSearchResults,
} from '../../../components/search-bar/utils'

const SEARCH_RESULT_AMOUNT = 10

interface SearchResultsProps extends HTMLAttributes<HTMLTableSectionElement> {
  faqArticleList: SupportArticle[]
  hrefPrefix: `/${string}`
}

export const findQueryValue = (paramsArray: [string, string][]) => {
  const paramQueryArray = paramsArray.find((array) => array.includes('query'))
  return paramQueryArray ? paramQueryArray[1] : ''
}

const IsANumber = (n: string) => /^[0-9]*$/.test(n)

export const SearchResults = ({
  faqArticleList,
  hrefPrefix,
  ...props
}: SearchResultsProps) => {
  const [...urlParamsArray] = useSearchParams()
  const [pageNumber, setPageNumber] = useState(0)
  const [visibleArticles, setVisibleArticles] = useState<SupportArticle[]>([])
  const [viewingAmount, setViewingAmount] = useState(SEARCH_RESULT_AMOUNT)
  const listContainerRef = useRef<HTMLUListElement | null>(null)

  const paramQueryValue = findQueryValue(urlParamsArray)
  const router = useRouter()

  const resultList = useMemo(() => {
    const nodeList = faqArticleList.map(
      (item: SupportArticle, index: number) => ({
        index,
        ...item,
        content: item.content.replace(
          /^([\s\S]+?)<h.>Related FAQs[\s\S]*?<\/h.>[\s\S]+$/,
          '$1',
        ),
      }),
    )
    const search = configureSearchEngine(nodeList, 'index', [
      'title',
      'content',
    ])
    return getSearchResults(paramQueryValue || '', search)
  }, [faqArticleList, paramQueryValue])

  useEffect(() => {
    const pageParamsArray = urlParamsArray.find((array) =>
      array.includes('page'),
    )

    const page =
      pageParamsArray && IsANumber(pageParamsArray[1]) && pageParamsArray[1]
        ? pageParamsArray[1]
        : 1
    if (page == 1) setViewingAmount(SEARCH_RESULT_AMOUNT)
    setPageNumber(+page)
    setVisibleArticles(resultList.slice(0, SEARCH_RESULT_AMOUNT * +page))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, paramQueryValue])

  const handleOnShowMoreButtonClick = () => {
    const currentLink =
      listContainerRef.current?.children[viewingAmount - 1].querySelector('a')
    currentLink?.focus()

    setViewingAmount(() => viewingAmount + SEARCH_RESULT_AMOUNT)
    setVisibleArticles(
      resultList.slice(0, viewingAmount * +pageNumber + SEARCH_RESULT_AMOUNT),
    )

    const newUrlParamsEntry = urlParamsArray.map((array) => {
      const [paramName, paramValue] = array
      if (paramName === 'page' && IsANumber(paramValue) && paramValue)
        return `page=${+paramValue + 1}`
      else if (paramName === 'page' && (!IsANumber(paramValue) || !paramValue))
        return 'page=2'
      else return array.join('=')
    })

    if (!newUrlParamsEntry.find((array) => array.includes('page')))
      newUrlParamsEntry.push('page=2')

    const newUrlParams = newUrlParamsEntry.join('&')

    router.replace(`?${newUrlParams}`, {
      scroll: false,
    })
  }

  if (!resultList.length) {
    return (
      <div className="tw-mb-20 tw-flex tw-w-full tw-rounded-2xl tw-border tw-border-dashed tw-border-blue60 tw-p-4 md:tw-w-3/5 lg:tw-w-1/2 xl:tw-w-2/5">
        <div className="tw-flex tw-flex-col tw-gap-4">
          <p
            role="heading"
            aria-level={2}
            className="tw-font-semibold"
          >{`We couldn't find any results for "${paramQueryValue}"`}</p>
          <p>
            Try narrowing your search by adding more words or start a new
            search.
          </p>
        </div>
        <NextImage src={NoResultsImg} alt="No results" />
      </div>
    )
  }

  return (
    <section className="tw-mb-14" {...props}>
      <Heading
        type="h3"
        as="h2"
      >{`${resultList.length} Results for "${paramQueryValue}"`}</Heading>
      <ul
        className="tw-mx-8 tw-mt-4 tw-flex tw-list-inside tw-list-disc tw-flex-col tw-gap-4 tw-text-blue30"
        data-length={visibleArticles.length}
        ref={listContainerRef}
      >
        {visibleArticles.map((article) => (
          <li key={article.title}>
            <Button asChild noExternalIcon variant="link">
              <Link
                href={`${hrefPrefix}${article.slug}`}
                trackingActionLabel={article.title}
                sectionLabel="Support Suggestions"
                aria-label={`open support article, ${article.title}`}
              >
                {article.title}
              </Link>
            </Button>
          </li>
        ))}
      </ul>
      {resultList.length > viewingAmount * pageNumber && (
        <div className="tw-m-auto tw-mt-12 tw-w-fit tw-text-center">
          <p>{`Viewing ${viewingAmount * pageNumber} out of ${
            resultList.length
          } results`}</p>
          <Button
            onClick={handleOnShowMoreButtonClick}
            className="tw-mt-4"
            size="sm"
          >
            Show more
          </Button>
        </div>
      )}
    </section>
  )
}
