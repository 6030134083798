'use client'

import { cn } from '@chaseweb/utils/cn'
import { Children, type ReactElement, useEffect, useState } from 'react'

export const imageAnimationTimer = 5000

export const HeroMultiImageInterval = ({
  children,
}: {
  children: ReactElement[]
}) => {
  const [currentImage, setCurrentImage] = useState(0)

  useEffect(() => {
    const childrenCount = Children.count(children)
    if (childrenCount > 1) {
      const intervalId = setInterval(() => {
        setCurrentImage((cur) => {
          return (cur + 1) % childrenCount
        })
      }, imageAnimationTimer)
      return () => {
        clearInterval(intervalId)
      }
    }
  }, [children])

  return Children.map(children, (child: ReactElement, index) => {
    return (
      <div
        data-testid={`hero-image-${index}`}
        className={cn(
          'tw-absolute tw-left-0 tw-top-0 -tw-z-30 tw-h-full tw-w-full tw-opacity-0 tw-transition-opacity tw-duration-300 tw-ease-in-out',
          {
            'tw-opacity-100': currentImage === index,
            '-tw-z-40 tw-delay-300': currentImage !== index,
          },
        )}
      >
        {child}
      </div>
    )
  })
}
