'use client'

import { Hero as HeroUI } from '@chaseweb/ui-library/src'
import { cn } from '@chaseweb/utils/cn'
import { useSplitStore } from '@chaseweb/utils/split'
import { useEffect, useRef } from 'react'

import { DownloadLink } from '@/components'
import type { FeatureTypeIdEnum } from '@/constants'
import { useHeaderCTAStore } from '@/lib/stores'

import type { ComponentProps } from '../../types'

export const HeroCta = ({
  featureData,
}: ComponentProps<FeatureTypeIdEnum.HERO_FEATURE>) => {
  const client = useSplitStore((state) => state.clients.user)
  const treatmentCtaText = client?.getTreatment('homepage_hero_cta_text')
  const treatmentCtaSize = client?.getTreatment('homepage_hero_cta_size')
  const treatmentCtaColour = client?.getTreatment('hero_cta_colour')

  const ctaRef = useRef<HTMLDivElement>(null)
  const { setCtaRef, setAlwaysVisible } = useHeaderCTAStore()

  useEffect(() => {
    if (client) {
      if (ctaRef.current) {
        setCtaRef(ctaRef)
        setAlwaysVisible(false)
      } else {
        setAlwaysVisible(true)
      }
    }
    return () => setCtaRef(null)
  }, [setCtaRef, client, setAlwaysVisible])

  const ctaLabel =
    treatmentCtaText === 'on' && featureData.isHomepage
      ? 'Open a Chase account'
      : 'Get the app'

  const isCtaBigSize = treatmentCtaSize === 'on' && featureData.isHomepage
  const isCtaWhite = treatmentCtaColour === 'on' && featureData.isHomepage

  return featureData.cta ? (
    <div ref={ctaRef} className="tw-min-h-[75px]">
      {client && (
        <HeroUI.Cta
          variant={isCtaWhite ? 'secondary' : featureData.variant}
          asChild
          noExternalIcon
          className={cn({
            'tw-px-8 tw-py-4': isCtaBigSize,
          })}
        >
          <DownloadLink sectionLabel="hero">{ctaLabel}</DownloadLink>
        </HeroUI.Cta>
      )}
    </div>
  ) : null
}
