import unset from 'lodash/fp/unset'
import { notFound } from 'next/navigation'

import type { Article, ArticleMeta } from '@/data/aem/articles/types'
import type { SlugPathType } from '@/types'
export const TILES_COLUMN = 6
export const LOAD_MORE_AMOUNT = 6

export const featuredSlugsSet = new Set<SlugPathType>([
  'romance-scams/',
  'bank-online-safely/',
  'problem-gambling/',
  'money-lessons-kids/',
  'identity-scams/',
  'avoid-elder-fraud/',
])

export const getCategories = (articles: Article[]) => {
  const hubArticleCategorySet = new Set<string>()
  articles.forEach(({ category }) => {
    hubArticleCategorySet.add(category)
  })
  return [...hubArticleCategorySet]
}

export const transformCategoryToLabel = (category: string) => {
  return category.replaceAll('--', ' & ').replaceAll('-', ' ')
}

export const transformCategoryToLabelAndCapitalise = (category: string) => {
  return transformCategoryToLabel(category)
    .split(' ')
    .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join(' ')
}

export const transformCategoryToPath = (category: string) => {
  return category
    .replaceAll(' & ', '--')
    .replaceAll('-&-', '--')
    .replaceAll(' ', '-')
    .toLowerCase()
}

export const filterCategoryArticles = (
  allArticles: Article[],
  category: string,
) => {
  return allArticles.filter((article) => article.category === category)
}

export const getArticleBySlug = (
  allArticles: Article[],
  slug: SlugPathType,
) => {
  const article = allArticles.find((article) => article.slug === slug)
  if (!article) return notFound()
  return article
}

export const getRelatedArticles = (
  allArticles: Article[],
  currentArticle: Article,
) => {
  return allArticles.filter(
    (article) =>
      article.category === currentArticle.category &&
      article.slug !== currentArticle.slug,
  )
}

export const getArticleMetaForArticlePage = (
  article: Article,
  index: number,
): ArticleMeta => {
  const {
    slug,
    title,
    readingTime,
    category,
    articleSummary,
    multiSizeImage: { tablet, desktop },
  } = article

  return {
    slug,
    title,
    readingTime,
    category: transformCategoryToLabel(category),
    articleSummary,
    image: {
      ...(index === 0 ? desktop : tablet ?? desktop),
      base64: desktop.base64,
    },
  }
}

export const stripPlaceholderData = (article: ArticleMeta): ArticleMeta => {
  return unset('multiSizeImage.desktop.base64', article)
}
