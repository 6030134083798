import * as JsSearch from 'js-search'

import type { SupportArticle } from '@/data/aem'

/**
 * Highlighting search words in suggestions box
 */
export const highlightWords = (result: string, pattern: RegExp) => {
  return result.split(pattern).map((word: string, index: number) => {
    // There is no way to guarantee uniquiness to the word
    const key = index.toString(32)
    return (
      <span
        key={key}
        className={`tw-text-sm tw-not-italic tw-shadow-none ${
          word !== undefined && pattern.test(word.toLowerCase())
            ? 'tw-font-bold'
            : 'tw-font-normal'
        }`}
      >
        {word}
      </span>
    )
  })
}

/**
 * Regex pattern for multi word search
 * @param keyWords
 */
export const formRegexForMultiWordSearch = (keyWords: string[]) => {
  const format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/
  return (
    keyWords
      // get rid of spaces and special characters
      .filter((word: string) => {
        return word !== '' && !format.test(word)
      })
      .map((word: string) => `(\\b)(${word})`.trim())
      .join('|')
  )
}

export const configureSearchEngine = (
  transformedNodes: SupportArticle[],
  idSearch: string,
  searchIndices: Array<string>,
) => {
  const searchEngine = new JsSearch.Search(idSearch)
  searchEngine.indexStrategy = new JsSearch.PrefixIndexStrategy()
  searchEngine.sanitizer = new JsSearch.LowerCaseSanitizer()
  searchEngine.searchIndex = new JsSearch.TfIdfSearchIndex(idSearch)
  searchIndices.forEach((searchIndex) => {
    searchEngine.addIndex(searchIndex)
  })
  searchEngine.addDocuments(transformedNodes)
  return searchEngine
}

export const getSearchResults = (value: string, search: JsSearch.Search) => {
  const queryResultSet = new Set()
  let requiredData: SupportArticle[] = []
  const splitWords = value.split(' ')
  if (splitWords.length > 1) {
    splitWords.forEach((word: string) => {
      search.search(word).forEach((output) => {
        queryResultSet.add(output)
      })
    })
  }
  if (value.length > 1) {
    search.search(value).forEach((output) => {
      queryResultSet.add(output)
    })
    // results based upon multiword as well as single word search
    const allData = [...queryResultSet] as SupportArticle[]
    // sort search result for multiwords in such a way that they appear on top
    const topSearchData = sortSearchResults(allData, value)
    // remove duplicate data if present
    requiredData = [...new Set([...topSearchData, ...allData])]
  }
  return requiredData
}

// sort data based upon indexOf values
const sortSearchResults = (data: SupportArticle[], value: string) => {
  return data
    .filter((item: SupportArticle) => {
      return item.title.toLowerCase().includes(value.toLowerCase())
    })
    .sort((a: SupportArticle, b: SupportArticle) => {
      // Sort results by matching title with keyword position in title
      if (
        a.title.toLowerCase().indexOf(value.toLowerCase()) >
        b.title.toLowerCase().indexOf(value.toLowerCase())
      ) {
        return 1
      }
      if (
        a.title.toLowerCase().indexOf(value.toLowerCase()) <
        b.title.toLowerCase().indexOf(value.toLowerCase())
      ) {
        return -1
      }
      return -1
    })
}
