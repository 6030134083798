'use client'

import {
  Button,
  Heading,
  Prose,
  Select,
  SVG,
  Tabs,
} from '@chaseweb/ui-library/src'
import type { ChartOptions } from 'chart.js'
import {
  Chart,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js'
Chart.register([
  TimeScale,
  LineController,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Title,
])
import 'chartjs-adapter-date-fns'

import { parse } from 'date-fns'
import merge from 'deepmerge'
import { useEffect, useMemo, useState } from 'react'

import { StyledLink } from '@/components'
import type { FeatureAvailabilityAndPerformanceReport } from '@/data/aem/main-pages/pageTransform/availability-and-performance-report'
import type { HrefExternals } from '@/types'

enum TabOptions {
  'openBanking' = 'Open banking performance',
  'appPerformance' = 'App performance',
}
type TabEnumType = keyof typeof TabOptions
const TabKeys = ['openBanking', 'appPerformance'] as const
const dateFormat = 'dd/MM/yyyy'
const QuarterList = [1, 2, 3, 4] as const
const graphOptions: ChartOptions = {
  clip: false,
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      type: 'time',
      time: {
        tooltipFormat: dateFormat,
        displayFormats: {
          day: dateFormat,
        },
      },
    },
  },
  plugins: {
    tooltip: {
      intersect: false,
      position: 'nearest',
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        boxHeight: 3,
      },
    },
  },
  animation: false,
  interaction: {
    mode: 'x',
  },
  layout: {
    padding: 10,
  },
  elements: {
    point: {
      radius: 1,
      hitRadius: 3,
    },
  },
} as const

const availabilityGraphOptions: ChartOptions = merge<ChartOptions>(
  graphOptions,
  {
    scales: {
      y: {
        suggestedMin: 90,
        suggestedMax: 100,
      },
    },
    plugins: {
      title: {
        display: true,
        text: 'Uptime %',
        font: { size: 20, weight: 'normal' },
      },
    },
  },
)

const performanceGraphOptions: ChartOptions = merge<ChartOptions>(
  graphOptions,
  {
    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax: 250,
      },
    },
    plugins: {
      title: {
        display: true,
        text: 'Performance (ms)',
        font: { size: 20, weight: 'normal' },
      },
    },
  },
)

export const Report = ({
  featureData,
}: {
  featureData: FeatureAvailabilityAndPerformanceReport
}) => {
  const [currentYear, setCurrentYear] = useState<number>(
    Math.max(...Object.keys(featureData.data).map(Number)),
  )
  const [currentQuarter, setCurrentQuarter] = useState<number>(
    Math.max(...Object.keys(featureData.data[currentYear]).map(Number)),
  )

  const currentQuarterData = useMemo(
    () => featureData.data[currentYear][currentQuarter],
    [currentQuarter, currentYear, featureData.data],
  )

  useEffect(() => {
    const dateList = currentQuarterData.dateStringList.map((dateString) =>
      parse(dateString, dateFormat, new Date()),
    )
    const chartAvailability = new Chart(
      document.querySelector('#graphAvailability') as HTMLCanvasElement,
      {
        type: 'line',
        data: {
          labels: dateList,
          datasets: [
            {
              label: 'Chase App Availability',
              data: currentQuarterData.appPerformance.graph.uptime,
              fill: false,
              // eslint-disable-next-line sonarjs/no-duplicate-string
              borderColor: 'rgb(0, 176, 240)',
              backgroundColor: 'rgb(0, 176, 240)',
              tension: 0.1,
            },
            {
              label: 'Open Banking Availability',
              data: currentQuarterData.openBanking.graph.uptime,
              fill: false,
              borderColor: 'rgb(31, 56, 100)',
              backgroundColor: 'rgb(31, 56, 100)',
              tension: 0.1,
            },
          ],
        },
        options: availabilityGraphOptions,
      },
    )
    const chartPerformance = new Chart(
      document.querySelector('#graphPerformance') as HTMLCanvasElement,
      {
        type: 'line',
        data: {
          labels: dateList,
          datasets: [
            {
              label: 'AIS',
              data: currentQuarterData.openBanking.graph.aisp,
              fill: false,
              borderColor: 'rgb(68, 114, 196)',
              backgroundColor: 'rgb(68, 114, 196)',
              tension: 0.1,
            },
            {
              label: 'PIS',
              data: currentQuarterData.openBanking.graph.pisp,
              fill: false,
              borderColor: 'rgb(237, 125, 50)',
              backgroundColor: 'rgb(237, 125, 50)',
              tension: 0.1,
            },
            {
              label: 'COF',
              data: currentQuarterData.openBanking.graph.cof,
              fill: false,
              borderColor: 'rgb(165, 165, 165)',
              backgroundColor: 'rgb(165, 165, 165)',
              tension: 0.1,
            },
            {
              label: 'Chase App',
              data: currentQuarterData.appPerformance.graph.response,
              fill: false,
              borderColor: 'rgb(0, 176, 240)',
              backgroundColor: 'rgb(0, 176, 240)',
              tension: 0.1,
            },
          ],
        },
        options: performanceGraphOptions,
      },
    )
    return () => {
      chartAvailability.destroy()
      chartPerformance.destroy()
    }
  }, [currentQuarterData])

  const [tabSelected, setTabSelected] = useState<TabEnumType>('openBanking')

  const yearList = useMemo(
    () => [
      ...Object.keys(featureData.data).sort((a, b) => Number(a) - Number(b)),
    ],
    [featureData],
  )

  const quarterMap = useMemo(
    () =>
      Object.keys(featureData.data[currentYear]).reduce(
        (prev, quarter) => ({ ...prev, [quarter]: true }),
        {
          1: false,
          2: false,
          3: false,
          4: false,
        },
      ),
    [featureData.data, currentYear],
  )

  const averageList = [
    {
      type: 'availability',
      value: currentQuarterData?.[tabSelected].availabilityAverage,
    },
    {
      type: 'performance',
      value: currentQuarterData?.[tabSelected].performanceAverage,
    },
  ]

  const handleYearSelectChange = (newValue: string) => {
    const year = Number(newValue)
    setCurrentYear(year)
    if (!featureData.data[year][currentQuarter]) {
      const quarter = Math.max(
        ...Object.keys(featureData.data[year]).map(Number),
      )
      setCurrentQuarter(quarter)
    }
  }

  const handleQuarterClick = (ev: React.PointerEvent<HTMLButtonElement>) => {
    const newQuarter = Number(ev.currentTarget.dataset.value)
    setCurrentQuarter(newQuarter)
  }

  const handleValueChange = (tabSelected: string) => {
    setTabSelected(tabSelected as TabEnumType)
  }

  return (
    <Prose className="prose-h2:tw-mb-0 prose-h2:tw-mt-16 prose-h3:tw-mt-12 prose-p:tw-mt-6 lg:prose-h2:tw-mt-18 lg:prose-h3:tw-mt-14">
      <Tabs.Root
        onValueChange={handleValueChange}
        defaultValue={tabSelected}
        className="tw-mt-10 lg:tw-mt-12 xl:tw-mt-14"
      >
        <Tabs.List
          mobileButtonClassName="tw-capitalize"
          mobile-aria-label-close="Closes tabs top menu"
          mobile-aria-label-open="Opens tabs top menu"
          role="tablist"
          aria-label="Category Tabs"
        >
          {TabKeys.map((tabKey) => (
            <Tabs.Trigger
              key={tabKey}
              value={tabKey}
              aria-controls={`panel-${tabKey}`}
              role="tab"
              aria-label={TabOptions[tabKey as TabEnumType] as string}
            >
              {TabOptions[tabKey as TabEnumType]}
            </Tabs.Trigger>
          ))}
        </Tabs.List>
      </Tabs.Root>
      <Heading type="h2">{TabOptions[tabSelected]}</Heading>
      <div className="tw-mt-10 md:tw-mt-12">
        <div className="tw-flex tw-flex-col md:tw-flex-row md:tw-gap-10">
          <Select.Root
            defaultValue={String(currentYear)}
            onValueChange={handleYearSelectChange}
            className="tw-min-w-[135px]"
          >
            <Select.Label>Select year</Select.Label>
            <Select.Trigger aria-label="Year">
              <Select.SelectIcon>
                <SVG name="Calendar" />
              </Select.SelectIcon>
              <Select.Value placeholder="Select a year…" />
              <Select.SelectIcon />
            </Select.Trigger>
            <Select.Content>
              <Select.ScrollUpButton />
              <Select.Viewport>
                {yearList.map((year) => (
                  <Select.Item key={year} value={year}>
                    <Select.ItemText>{year}</Select.ItemText>
                    <Select.ItemIndicator />
                  </Select.Item>
                ))}
              </Select.Viewport>
              <Select.ScrollDownButton />
            </Select.Content>
          </Select.Root>
          <div>
            <div
              aria-hidden
              className="tw-mb-1 tw-mt-8 tw-font-semibold md:tw-mt-0"
            >
              Select quarter
            </div>
            <div className="tw-flex tw-gap-2">
              {QuarterList.map((quarter) => (
                <Button
                  variant={quarter === currentQuarter ? 'primary' : 'secondary'}
                  aria-label={`Select quarter Q${quarter}`}
                  className="tw-min-w-fit tw-border-grey50 tw-p-3.5 tw-text-sm tw-leading-tight"
                  key={quarter}
                  data-value={quarter}
                  disabled={!quarterMap[quarter]}
                  role="radio"
                  aria-checked={quarter === currentQuarter}
                  onClick={handleQuarterClick}
                >
                  <div aria-hidden>Q{quarter}</div>
                </Button>
              ))}
            </div>
          </div>
        </div>
        <div className="tw-mt-14 tw-grid tw-w-full tw-gap-6 md:tw-grid-cols-2">
          {averageList.map((averageData) => (
            <div
              id={`panel-${tabSelected}`}
              key={averageData.type}
              role="region"
              className="tw-flex tw-flex-col tw-justify-center tw-rounded-3xl tw-bg-blue90 tw-p-8 tw-text-center tw-align-middle"
              aria-label={`Quarterly average ${averageData.type} ${averageData.value}`}
            >
              <div className="tw-font-semibold tw-text-blue30" aria-hidden>
                Quarterly average {averageData.type}
              </div>
              <div className="ui-h1 tw-mt-1" aria-hidden>
                {averageData.value}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Heading type="h3">Availability</Heading>
      <div className="tw-h-96">
        <canvas
          id="graphAvailability"
          className="tw-border tw-border-grey70"
          aria-label={`Availability graph of the ${currentQuarter}˚ quarter of ${currentYear}. The full data can be found at the pdf available for download.`}
        ></canvas>
      </div>
      <Heading type="h3">Performance</Heading>
      <div className="tw-h-96">
        <canvas
          id="graphPerformance"
          className="tw-border tw-border-grey70"
          aria-label={`Performance graph of the ${currentQuarter}˚ quarter of ${currentYear}. The full data can be found at the pdf available for download.`}
        ></canvas>
      </div>
      <div className="tw-mt-8 tw-text-center">
        <StyledLink
          variant="primary"
          href={currentQuarterData.reportUrl as HrefExternals}
          notTracked
          aria-label="Download full report data"
          role="link"
          target="_blank"
        >
          Download data
        </StyledLink>
      </div>
    </Prose>
  )
}
