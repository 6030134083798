'use client'

import { useSplitStore } from '@chaseweb/utils/split'
import type { ReactElement, ReactNode } from 'react'

export const LabelFlagger = ({
  children,
}: {
  children: ReactElement
}): ReactNode => {
  const client = useSplitStore((state) => state.clients.user)
  const treatment = client?.getTreatmentWithConfig(
    'homepage_feature_label',
  ).treatment

  if (treatment === 'on') {
    return children
  }
  return null
}
