'use client'

import { Heading, Tabs } from '@chaseweb/ui-library/src'
import { useRouter } from 'next/navigation'

import type { FeatureTypeIdEnum } from '@/constants'
import type { FeatureDataFilteredByType } from '@/data/aem/types'

type Props = {
  featureData: FeatureDataFilteredByType<FeatureTypeIdEnum.TABS_FEATURE>
}

export const TabsComponent = ({ featureData }: Props) => {
  const { push } = useRouter()
  const handleValueChange = (newTab: string) => {
    push(newTab)
  }

  return (
    <>
      <Tabs.Root
        onValueChange={handleValueChange}
        defaultValue={featureData.path}
        defaultTabLabel={featureData.selectedTabLabel}
      >
        <Tabs.List
          mobileButtonClassName="tw-capitalize"
          mobile-aria-label-close="Closes list"
          mobile-aria-label-open="Opens list"
          aria-label={featureData.ariaLabel}
        >
          {featureData.tabList.map((tab) => {
            return (
              <Tabs.Trigger
                key={tab.path}
                value={tab.path}
                aria-controls={`panel-content`}
                aria-label={tab.label}
              >
                {tab.label}
              </Tabs.Trigger>
            )
          })}
        </Tabs.List>
      </Tabs.Root>
      {featureData.shouldAddHeader && (
        <Heading type="h1" context="pageTitle" className="tw-container">
          {featureData.selectedTabLabel}
        </Heading>
      )}
    </>
  )
}
