'use client'

import { Button, Heading } from '@chaseweb/ui-library/src'
import { cn } from '@chaseweb/utils/cn'
import { useRouter, useSearchParams } from 'next/navigation'
import type { HTMLAttributes } from 'react'
import { type FC, Suspense, useEffect, useRef, useState } from 'react'

import { Link } from '@/components'
import type { ArticleMeta } from '@/data/aem/articles/types'
import { parseContent } from '@/lib/parse-content'
import type { RelativePathOrHrefExternalType } from '@/types'

import { Tile } from './tile'
import { LOAD_MORE_AMOUNT, TILES_COLUMN } from './utils'

interface LoadMoreProps extends HTMLAttributes<HTMLTableSectionElement> {
  articleList: ArticleMeta[]
  linkPathPrefix: RelativePathOrHrefExternalType
  title?: string
}

const TILES_AMOUNT_PRELOADED = TILES_COLUMN + 1

const IsANumber = (n: string) => /^[0-9]*$/.test(n)

const LoadMoreImplementation: FC<LoadMoreProps> = ({
  articleList,
  linkPathPrefix,
  className,
  title,
  ...props
}) => {
  const tileContainer = useRef<HTMLDivElement>(null)
  const totalAmount = articleList.length + TILES_AMOUNT_PRELOADED
  const router = useRouter()
  const [...urlParamsArray] = useSearchParams()
  const [pageNumber, setPageNumber] = useState(0)
  const [visibleTiles, setVisibleTiles] = useState<ArticleMeta[]>([])

  useEffect(() => {
    const pageParamsArray = urlParamsArray.find((array) =>
      array.includes('page'),
    )
    const page =
      pageParamsArray && IsANumber(pageParamsArray[1]) && pageParamsArray[1]
        ? pageParamsArray[1]
        : 1
    setPageNumber(() => +page)
    setVisibleTiles(articleList.slice(0, LOAD_MORE_AMOUNT * +page))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!articleList.length) {
    return null
  }

  const handleLoadMore = () => {
    const currentLink = tileContainer.current?.children[
      pageNumber * LOAD_MORE_AMOUNT - 1
    ] as HTMLAnchorElement
    currentLink.focus()

    setPageNumber(pageNumber + 1)
    setVisibleTiles(() =>
      articleList.slice(0, (pageNumber + 1) * LOAD_MORE_AMOUNT),
    )

    const newUrlParamsArray = urlParamsArray.map((array) => {
      const [paramName, paramValue] = array
      if (paramName === 'page' && IsANumber(paramValue) && paramValue)
        return `page=${+paramValue + 1}`
      else if (paramName === 'page' && (!IsANumber(paramValue) || !paramValue))
        return 'page=2'
      else return array.join('=')
    })

    if (!newUrlParamsArray.find((array) => array.includes('page')))
      newUrlParamsArray.push('page=2')

    const newUrlParams = newUrlParamsArray.join('&')

    router.replace(`?${newUrlParams}`, {
      scroll: false,
    })
  }

  return (
    <section
      className={cn('tw-mt-12', className)}
      {...props}
      data-testid="LoadMore"
    >
      {title && (
        <Heading type="h2" className="tw-mb-8 md:tw-mb-10">
          {title}
        </Heading>
      )}
      <div
        className="tw-grid tw-grid-cols-1 tw-gap-4 md:tw-grid-cols-1 lg:tw-grid-cols-2"
        ref={tileContainer}
      >
        {visibleTiles.map((tile, index) => {
          const isLargeTile =
            index >= LOAD_MORE_AMOUNT &&
            (index % LOAD_MORE_AMOUNT === 0 || index % LOAD_MORE_AMOUNT === 1)
          return (
            <Link
              data-testid={isLargeTile ? 'tile-lg' : 'tile-sm'}
              aria-label={`open article, ${tile.category}, ${tile.title}, ${tile.readingTime} read`}
              key={tile.title}
              href={`${linkPathPrefix}${tile.slug}`}
              trackingActionLabel="requestAdditionalArticles"
              sectionLabel="body"
            >
              {isLargeTile ? (
                <Tile
                  variant="lg"
                  label={tile.category}
                  title={tile.title}
                  description={
                    tile.articleSummary && parseContent(tile.articleSummary)
                  }
                  summary={tile.readingTime}
                  image={tile.image}
                />
              ) : (
                <Tile
                  variant="sm"
                  label={tile.category}
                  title={tile.title}
                  summary={tile.readingTime}
                  image={tile.image}
                />
              )}
            </Link>
          )
        })}
      </div>
      {totalAmount > LOAD_MORE_AMOUNT * pageNumber + TILES_AMOUNT_PRELOADED && (
        <div className="tw-m-auto tw-my-16 tw-w-fit tw-text-center lg:tw-my-14">
          <p className="tw-my-4">{`Viewing ${
            LOAD_MORE_AMOUNT * pageNumber + TILES_AMOUNT_PRELOADED
          } out of ${totalAmount} articles`}</p>
          <Button onClick={handleLoadMore} size="sm">
            Load more articles
          </Button>
        </div>
      )}
    </section>
  )
}

export const LoadMore: FC<LoadMoreProps> = ({ ...props }) => {
  return (
    // Wrapping LoadMore in a Suspense boundary ensures the whole page does not de-opt to client-side rendering
    // More info here: https://nextjs.org/docs/messages/missing-suspense-with-csr-bailout
    <Suspense>
      <LoadMoreImplementation {...props} />
    </Suspense>
  )
}
