import { ArticleTile } from '@chaseweb/ui-library/src'
import { type FC } from 'react'

import { NextImage } from '@/components'
import type { ImageWithPlaceholderData } from '@/types'

import { transformCategoryToLabel } from './utils'

export interface TileProps {
  variant: 'sm' | 'md' | 'lg'
  label: string
  title: string
  description?: string | JSX.Element | JSX.Element[]
  summary?: string
  image: ImageWithPlaceholderData
  priority?: boolean
}

export const Tile: FC<TileProps> = ({
  variant,
  image,
  label,
  title,
  description,
  summary,
  priority,
}) => (
  <ArticleTile.Root hasHoverEffect variant={variant} aria-hidden>
    <ArticleTile.ImageWrapper className="tw-relative tw-aspect-square">
      <NextImage
        fill
        alt=""
        data-testid="tile-img"
        priority={priority}
        src={image.src}
        placeholder={priority && !!image.base64 ? 'blur' : 'empty'}
        blurDataURL={image.base64}
      />
    </ArticleTile.ImageWrapper>
    <ArticleTile.Content>
      <ArticleTile.Label className="tw-capitalize">
        {transformCategoryToLabel(label)}
      </ArticleTile.Label>

      <ArticleTile.Title>{title}</ArticleTile.Title>

      {description && (
        <ArticleTile.Description>{description}</ArticleTile.Description>
      )}

      {summary && <ArticleTile.Summary>{summary}</ArticleTile.Summary>}
    </ArticleTile.Content>
  </ArticleTile.Root>
)
